import React from 'react'
import { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    maxWidth: 420,
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingLeft: 16,
    paddingRight: 16,
    [theme.breakpoints.down('sm')]: {
      maxWidth: 650,
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: 980,
      paddingLeft: 40,
      paddingRight: 40,
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: 1440,
      paddingLeft: 80,
      paddingRight: 80,
    },
  },
}))

interface IntroContainerProps {
  children: React.ReactNode
  subContent?: React.ReactNode
}

export const Container = (props: IntroContainerProps) => {
  const classes = useStyles()
  return <div className={classes.container}>{props.children}</div>
}
