import React from 'react';


export const StateContext = React.createContext(null);

export default function AppStateProvider(props) {
  const [notification, setNotification] = React.useState();
  const [currentId,setCurrentId] = React.useState("")
  const [service,setService] = React.useState({serviceIndex:undefined,workflows:undefined})
  let contextValue = {
    notification,
    setNotification,
    service,
    setService,
    currentId,
    setCurrentId
  };

  return <StateContext.Provider value={contextValue}>{props.children}</StateContext.Provider>;
}

export function useAppState() {
  const context = React.useContext(StateContext);
  if (!context) {
    throw new Error('useAppState must be used within the AppStateProvider');
  }
  return context;
}
