import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useAppState } from '../../state';

export const LoadingIndicator = () => {
  const { isFetching } = useAppState();

  if (!isFetching) return null;
  return (
    <div style={{ position: 'fixed', zIndex: 1000000, top: 0, width: '100%' }}>
      <LinearProgress />
    </div>
  );
};

export default LoadingIndicator;
