/* eslint-disable no-nested-ternary */
import React, { useState, useRef } from 'react'
import { Link } from 'gatsby'
import {
  Button,
  ClickAwayListener,
  IconButton,
  ListItemText,
  Typography,
  Menu,
  MenuItem
} from '@material-ui/core'
import { Container } from 'components/PageContainer'
import Logo from 'assets/icons/logosSD/logoWhite.svg'
import ScrolledLogo from 'assets/icons/logosSD/logo.svg'
import FBWhite from 'assets/icons/socialMedia/FB white.svg'
import LIWhite from 'assets/icons/socialMedia/LI white.svg'
import IGWhite from 'assets/icons/socialMedia/IG white.svg'
import FBBlue from 'assets/icons/socialMedia/Icon_FB.svg'
import LIBlue from 'assets/icons/socialMedia/Icon_Li.svg'
import IGBlue from 'assets/icons/socialMedia/Icon_Insta.svg'
import MenuIcon from '@material-ui/icons/Menu'
import { StyledMenu, StyledMenuItem } from '../StyledMenu/StyledMenu'
import './navigation.scss'

export default function Navigation({classes}) {
  const [menuOpen, setMenuOpen] = useState(false)
  const [aboutMenuOpen, setAboutMenuOpen] = useState(false)
  const anchorRef = useRef(null)
  const [scrollState, setScrollState] = React.useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [navbar, setNavbar] = useState(false)
  let listener = null

  React.useEffect(() => {
    listener = document.addEventListener('scroll', () => {
      const scrolled = document.scrollingElement.scrollTop
      if (scrolled && scrolled >= 70) {
        if (scrollState !== true) {
          setScrollState(true)
          setNavbar(true)
        }
      } else {
        if (scrollState !== false) {
          setScrollState(false)
          setNavbar(false)
        }
      }
    })
    return () => {
      document.removeEventListener('scroll', listener)
    }
  }, [scrollState])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const socialMediaIconBGColor = '#016CB2'

  return (
    <nav
      className={`navigation ${navbar ? "active" : ""} ${classes ? "" : "navigation_blue"}`}
    >
      <Container maxWidth="lg">
        <div className="container">
          <Link to="/" className="logo">
            {navbar ?
              <img alt="Logo SwiftDoc" src={ScrolledLogo} className="imageLogo" />
              :
              <img alt="Logo SwiftDoc" src={Logo} className="imageLogo" />
            }
          </Link>
          <div className="linkContainer">
            <div className='aboutMenu' />
            <Link to="/">
              <Typography className={navbar ? 'homeNavItemBlue' : 'homeNavItemWhite'}>Home</Typography>
            </Link>

            <ClickAwayListener onClickAway={handleClose}>
              <>
                <Typography 
                  className={navbar ? 'homeNavItemBlue' : 'homeNavItemWhite'} 
                  style={{color: 'white'}} 
                  aria-controls="simple-menu" 
                  aria-haspopup="true"
                  onClick={handleClick}>
                    About
                </Typography>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <Link to="/meet-the-team/" className='menuItems'>
                    <MenuItem >Meet The Team</MenuItem>
                  </Link>

                  <Link to="/services/" className='menuItems'>
                    <MenuItem >Services</MenuItem>
                  </Link>

                  <Link to="/how-it-works/"className='menuItems'>
                    <MenuItem >How It Works</MenuItem>
                  </Link>

                  <Link to="/faq/" className='menuItems'>
                    <MenuItem >FAQs</MenuItem>
                  </Link>

                  <a href="https://app.swiftdoc.com/careers" className='menuItems'>
                    <MenuItem >Work With Us</MenuItem>
                  </a>

                  <Link to="/about/" className='menuItems'>
                    <MenuItem >About SwiftDoc</MenuItem>
                  </Link>

                  <Link to="/contributors/" className='menuItems'>
                    <MenuItem >Contributors</MenuItem>
                  </Link>
                </Menu>
              </>
            </ClickAwayListener>

            <Link to="/news/">
              <Typography className={navbar ? 'homeNavItemBlue' : 'homeNavItemWhite'}>News</Typography>
            </Link>
            <Link to="/contact">
              <Typography className={navbar ? 'homeNavItemBlue' : 'homeNavItemWhite'}>Contact</Typography>
            </Link>
            <a href="https://app.swiftdoc.com">
              <Button
                className="bookAppointment"
                style={{ backgroundColor: '#fff', color: '#016CB2', border: "2px solid #016cb2" }}
                variant={!scrollState ? 'outlined' : 'contained'}
              >
                BOOK APPOINTMENT
              </Button>
            </a>
           {!navbar ?  
            <div className="socialMediaIconContainer">
              <a
                className="socialMediaIcon"
                style={{ backgroundColor: "transparent" }}
                href="https://www.facebook.com/swiftdocgp"
                target="_blank"
                rel="noreferrer"
              >
                <img alt="Facebook" src={FBWhite} />
              </a>
              <a
                className="socialMediaIcon"
                style={{ backgroundColor: "transparent" }}
                href="https://au.linkedin.com/company/swiftdoc-pty-ltd"
                target="_blank"
                rel="noreferrer"
              >
                <img alt="Linkedin" src={LIWhite} />
              </a>
              <a
                className="socialMediaIcon"
                style={{ backgroundColor: "transparent" }}
                href="https://www.instagram.com/swiftdoc/"
                target="_blank"
                rel="noreferrer"
              >
                <img alt="Instagram" src={IGWhite} />
              </a>
            </div>
            : 
            <div className="socialMediaIconContainer">
              <a
                className="socialMediaIcon"
                style={{ backgroundColor: "transparent" }}
                href="https://www.facebook.com/swiftdocgp"
                target="_blank"
                rel="noreferrer"
              >
                <img alt="Facebook" src={FBBlue} />
              </a>
              <a
                className="socialMediaIcon"
                style={{ backgroundColor: "transparent" }}
                href="https://au.linkedin.com/company/swiftdoc-pty-ltd"
                target="_blank"
                rel="noreferrer"
              >
                <img alt="Linkedin" src={LIBlue} />
              </a>
              <a
                className="socialMediaIcon"
                style={{ backgroundColor: "transparent" }}
                href="https://www.instagram.com/swiftdoc/"
                target="_blank"
                rel="noreferrer"
              >
                <img alt="Instagram" src={IGBlue} />
              </a>
            </div>}
          </div>
          <ClickAwayListener onClickAway={() => setMenuOpen(false)}>
            <>
              <IconButton
                className="mobileNavButton"
                ref={anchorRef}
                onClick={() => setMenuOpen(true)}
                aria-label="Mobile Navigation Button"
              >
                <MenuIcon
                  style={{
                    color: '#016CB2',
                  }}
                />
              </IconButton>

              <StyledMenu
                id="customized-menu"
                anchorEl={anchorRef.current}
                keepMounted
                open={menuOpen}
                onClose={() => setMenuOpen((isOpen) => !isOpen)}
              >
                <Link to="/">
                  <StyledMenuItem>
                    <ListItemText primary="Home" />
                  </StyledMenuItem>
                </Link>
                <Link to="/news">
                  <StyledMenuItem>
                    <ListItemText primary="News" />
                  </StyledMenuItem>
                </Link>
                <Link to="/contact">
                  <StyledMenuItem>
                    <ListItemText primary="Contact" />
                  </StyledMenuItem>
                </Link>
                  <StyledMenuItem onClick={ handleClick }>
                    <ListItemText primary="About" />
                  </StyledMenuItem>
              </StyledMenu>
            </>
          </ClickAwayListener>
        </div>
      </Container>
    </nav>
  )
}
