import React from 'react';
import { Grid, Typography } from '@material-ui/core';
// import './home.scss'
import AppleDownload from 'components/DownloadButtons/Apple';
import PlaystoreDownload from 'components/DownloadButtons/Playstore';
import SDIcon from '../../assets/icons/logosSD/SDDownloadIcon.svg'

const DownloadPromote = ({backgroundColor, white}) => {
  return (
      <section className="downloadSection" style={{backgroundColor:backgroundColor}}>
        <Grid container direction="row" justify="center" alignItems="center" spacing={2}>
          <Grid item xl={12} md={12} xs={12}>
            <div className="downloadIconContainer">
              <div className="downloadIcon"><img className="downloadIconImage" src={SDIcon} alt='DownloadIcon'/></div>
            </div>
            <Typography variant="h2" className="downloadText blackColor" style={{color:white ? '#fff':''}}>
              Download the
            </Typography>
            <Typography variant="h2" className="downloadText blueColor" style={{color:white ? '#fff':''}}>
              Swiftdoc App
            </Typography>
            <Typography className="downloadTextParagraph darkGreyColor" style={{color:white ? '#fff':'#6A7B93'}}>
              Get Instant Access to Online Doctors
            </Typography>
            <div className="downloadButtonsHome">
              <PlaystoreDownload />
              <AppleDownload />
            </div>
          </Grid>
        </Grid>
      </section>
  );
};

export default DownloadPromote;