import { createTheme } from '@material-ui/core';
import SwFontTTF from './assets/fonts/GalanoGrotesqueAltRegular.ttf';

import SwFontTTFBold from './assets/fonts/GalanoGrotesqueAltBold.otf';

const SwFont = {
  fontFamily: 'SwFont',
  src: `url(${SwFontTTF}) format('truetype')`,
  fontWeight: '400',
  fontDisplay: 'swap'
};

const SwFontBold = {
  fontWeight: '500',
  fontFamily: 'SwFont',
  src: `url(${SwFontTTFBold}) format('truetype')`,
  fontDisplay: 'swap'
};
declare module '@material-ui/core/styles/createTheme' {
  interface Theme {
    sidebarWidth: number;
    sidebarMobileHeight: number;
    brand: string;
    footerHeight: number;
    mobileTopBarHeight: number;
    mobileFooterHeight: number;
    sidebarMobilePadding: number;
    participantBorderWidth: number;
    backgroundSD: string;
  }

  interface ThemeOptions {
    sidebarWidth?: number;
    sidebarMobileHeight?: number;
    brand: string;
    footerHeight: number;
    mobileTopBarHeight: number;
    mobileFooterHeight: number;
    sidebarMobilePadding: number;
    participantBorderWidth: number;
    backgroundSD: string;
  }
}

const defaultTheme = createTheme();

export default createTheme({
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '*::-webkit-scrollbar': {
          width: 10,
          backgroundColor: 'white',
        },
        '*::-webkit-scrollbar-track': {
          '-webkit-box-shadow': 'inset 0 0 2px rgba(0,0,0,0.00)',
        },
        '*::-webkit-scrollbar-thumb': {
          borderRadius: 5,
          backgroundColor: '#dadada',
        },
        'html, body, #root': {
          height: '100%',
          background: '#F1F6FE',
          backgroundRepeat: 'no-repeat',
        },
        '@font-face': [SwFont, SwFontBold],

        a: {
          textDecoration: 'none',
        },
        hr: {
          display: 'block',
          height: 1,
          border: 0,
          borderTop: '1px solid hsl(0,0%,80%)',
          margin: '1em 0',
          padding: 0,
        },
      },
    },
    MuiButton: {
      root: {
        borderRadius: '4px',
        textTransform: 'none',
        color: 'rgb(40, 42, 43)',
        fontSize: '0.9rem',
        transition: defaultTheme.transitions.create(['background-color', 'box-shadow', 'border', 'color'], {
          duration: defaultTheme.transitions.duration.short,
        }),
      },
      text: {
        padding: '6px 14px',
      },
      contained: {
        boxShadow: 'none',
        backgroundColor: '#016cb2',
        color: 'white',
        '&:hover': {
          boxShadow: 'none',
          background: 'linear-gradient(33deg, rgba(1,108,178,1) 49%, rgba(25,213,211,1) 89%)',
        },
      },
      outlinedPrimary: {
        border: '2px solid #027AC5',
        '&:hover': {
          border: '2px solid rgb(1, 85, 137)',
        },
      },
      startIcon: {
        marginRight: '6px',
      },
    },
    MuiTypography: {
      body1: {
        color: 'rgb(40, 42, 43)',
        fontSize: '0.9rem',
      },
      h1: {
        fontWeight: 700,
        fontSize: 26,
        lineHeight: '120%',
        [defaultTheme.breakpoints.only('md')]: {
          fontSize: 26,
        },
        [defaultTheme.breakpoints.up('lg')]: {
          fontSize: 42,
          lineHeight: '52px',
        },
      },
      h2: {
        fontWeight: 700,
        fontSize: 23,
        lineHeight: '115%',
        [defaultTheme.breakpoints.only('md')]: {
          fontSize: 30,
        },
        [defaultTheme.breakpoints.up('lg')]: {
          fontSize: 35,
          lineHeight: '52px',
        },
      },
      h3: {
        fontWeight: 700,
        fontSize: 28,
        lineHeight: '34px',
      },
    },
    MuiInputBase: {
      root: {
        fontSize: '0.9rem',
        color: '#263942',
      },
    },
    MuiSelect: {
      root: {
        padding: '0.85em',
      },
    },
    MuiDialogActions: {
      root: {
        padding: '16px',
      },
    },
    MuiTextField: {
      root: {
        color: 'rgb(40, 42, 43)',
      },
    },
    MuiInputLabel: {
      root: {
        color: 'rgb(40, 42, 43)',
        fontSize: '1.1rem',
        marginBottom: '0.2em',
        fontWeight: 500,
      },
    },
    MuiOutlinedInput: {
      notchedOutline: {
        borderColor: 'rgb(136, 140, 142)',
      },
    },
  },
  typography: {
    fontFamily: 'SwFont, Poppins, Inter, sans-serif',
  },
  palette: {
    primary: {
      main: '#016cb2',
      light: '#19d5d3',
    },
  },
  brand: '#E22525',
  footerHeight: 72,
  mobileFooterHeight: 73,
  sidebarWidth: 355,
  sidebarMobileHeight: 90,
  sidebarMobilePadding: 8,
  participantBorderWidth: 2,
  mobileTopBarHeight: 52,
  backgroundSD: 'linear-gradient(33deg, rgba(1,108,178,1) 49%, rgba(25,213,211,1) 89%)',
});
// 'linear-gradient(33deg, rgba(1,108,178,1) 49%, rgba(25,213,211,1) 89%)',
