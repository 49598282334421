import React from 'react';
import PlaystoreLogo from '../../assets/icons/playstore.svg';
import './downloadButtons.scss';

export default function PlaystoreButton() {

  return (
    <a href="https://play.google.com/store/apps/details?id=com.app.swiftdoc" className="downloadItem">
      <img alt="playstore_logo" src={PlaystoreLogo} />
    </a>
  );
}
