// @flow strict
import React from 'react'
import Navigation from '../Navigation'
import PropTypes from 'prop-types'
import theme from 'src/theme'
import { useLocation } from '@reach/router'
import { CssBaseline, MuiThemeProvider } from '@material-ui/core'
import LoadingIndicator from 'components/LoadingIndicator'
import Footer from 'components/Footer'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { Helmet } from 'react-helmet'
import { withPrefix } from 'gatsby'

const Layout = ({
  children,
  title,
  description,
  image,
  article,
  pages,
  location,
  heroFormat,
  headerTransparent,
  navClasses,
}) => {
  const { pathname } = useLocation()
  const { site } = useStaticQuery(query)

  const {
    defaultTitle,
    titleTemplate,
    defaultDescription,
    siteUrl,
    defaultImage,
    twitterUsername,
  } = site.siteMetadata

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${siteUrl}${image}` || defaultImage,
    url: `${siteUrl}${pathname}`,
  }
  console.log(seo)
  return (
    <MuiThemeProvider theme={theme}>
      <LoadingIndicator />
      <CssBaseline />
      <GatsbySeo
        language="en"
        title={seo.title}
        description={seo.description}
        openGraph={{
          url: seo.url,
          title: seo.title,
          description: seo.description,
          site_name: 'SwiftDoc',
        }}
        twitter={{
          handle: '@handle',
          site: '@site',
          cardType: 'summary_large_image',
        }}
      />
      {/* 
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta
          name="description"
          content="Video chat to your doctor from anywhere in Australia! SwiftDoc provides an online doctor service for diagnosis, treatment, prescriptions, referrals and more."
        />
        <meta property="og:title" content="Consumer" />
        <meta
          property="og:description"
          content="Video chat to your doctor from anywhere in Australia! SwiftDoc provides an online doctor service for diagnosis, treatment, prescriptions, referrals and more."
        />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:creator" content="Swiftdoc" />
        <meta name="twitter:title" content="Consumer" />
        <meta
          name="twitter:description"
          content="Video chat to your doctor from anywhere in Australia! SwiftDoc provides an online doctor service for diagnosis, treatment, prescriptions, referrals and more."
        />
        <meta name="copyright" content="Swiftdoc" />
        <meta name="robots" content="index/follow" />
        <meta
          name="DC.title"
          content="SwiftDoc allows you to see an online doctor using video calling on your phone or desktop. All you have to do is book an appointment and we will do the rest!"
        />
        <meta
          name="keywords"
          content="online doctor,pharmacy service,treatment,diagnosis,consultations,prescriptions,online medication,video doctor"
        />
      </Helmet>
      */}
      <Helmet>
        <script
          src="https://cdnjs.cloudflare.com/ajax/libs/axios/1.4.0/axios.min.js"
          integrity="sha512-uMtXmF28A2Ab/JJO2t/vYhlaa/3ahUOgj1Zf27M5rOo8/+fcTUVH0/E0ll68njmjrLqOBjXM3V9NiPFL5ywWPQ=="
          crossorigin="anonymous"
          referrerpolicy="no-referrer" />
        <script src={withPrefix('ai-widget.js?v=1.4')} type="text/javascript" />
      </Helmet>
      <Navigation
        headerTransparent={headerTransparent}
        pages={pages}
        heroFormat={heroFormat}
        location={location}
        classes={navClasses}
      />
      {children}
      <Footer />
    </MuiThemeProvider>
  )
}

export default Layout

Layout.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  article: PropTypes.bool,
}
Layout.defaultProps = {
  title: null,
  description: null,
  image: null,
  article: false,
}
const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: url
        defaultImage: image
        twitterUsername
      }
    }
  }
`
