import React from 'react';
import AppleLogo from '../../assets/icons/apple.svg';
import './downloadButtons.scss';

export default function AppleButton() {
  return (
    <a
      href="https://itunes.apple.com/au/app/swiftdoc/id1178442509"
      className="downloadItem"
    >
      <img alt="apple_logo" src={AppleLogo} />
    </a>
  );
}
