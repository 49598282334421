import React from 'react'
import LogoWhite from 'assets/icons/logosSD/logoWhite.svg'
import { Link } from "gatsby"
import DownloadPromote from 'components/Home/DownloadPromote'
import './footer.scss'
import TermsPDF from 'assets/SwiftDoc-terms-of-use.pdf'
import PrivacyPDF from 'assets/SwiftDoc-privacy-statement.pdf'


const Footer = () => {
  return (
    <>
      <DownloadPromote white backgroundColor={"#19D5D3"} />

      <footer className='blueBackground'>
      <div style={{ height: 150, overflow: 'hidden', position:'absolute',top:-100,width:'100%' }}>
        <svg
          viewBox="0 0 500 150"
          preserveAspectRatio="none"
          style={{ height: '100%', width: '100%' }}
        >
          <path
            d="M0.00,49.98 C149.99,150.00 353.83,62.66 501.12,22.20 L500.00,150.00 L0.00,150.00 Z"
            style={{ stroke: 'none', fill: '#016CB2' }}
          ></path>
        </svg>
        
      </div>
      <div style={{ height: 150, overflow: 'hidden', position:'absolute',top:-100,width:'100%' }}>
  

        <svg
          viewBox="0 0 500 150"
          preserveAspectRatio="none"
          style={{ height: '100%', width: '100%' }}
        >
          <path
            d="M0 108.901V1090.55C280.56 1084.77 623.58 1073.29 1066.52 1187.44C1265.28 1238.66 1585.99 1273.72 1919.89 1259.43L1920 0.944336C1345.91 109.456 682.33 315.435 0 108.901Z"
            style={{ stroke: 'none', fill: '#016CB2', opacity:0.6 }}
          ></path>
        </svg>
        
      </div>
        <div
          className='responsivePadding'
        >
          <img
            src={LogoWhite}
            alt="Swiftdoc Isologo"
            className='imageLogo'
          />

          <h3
            variant="h3"
            className="centerText whiteColor footerText"
          >
            Online Doctor Service Australia
          </h3>
        </div>
        <div className='copyrightContainer'>
          <p className='copyrightText' style={{ color: 'white' }}>
            © COPYRIGHT 2021 - SWIFTDOC | <Link to={TermsPDF}>TERMS OF USE</Link> | <Link to={PrivacyPDF}>PRIVACY STATEMENT | Ph: 1800 794 383</Link>
          </p>
        </div>
      </footer>
    </>
  )
}

export default Footer


